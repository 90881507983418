<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
      drawerSize="extra-large"
    >
      <template v-slot:content>
        <ArticleSummary
          v-if="showArticleSummary"
          :articleKey="selectedArticle"
        />
      </template>
    </ui-component-drawer>

    <p class="title">Publish requests</p>

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>Title</th>
          <th>Source</th>
          <th>Status</th>
          <th>Published on</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(request, index) in requests" :key="index">
          <td>
            <a @click="setShowArticleSummary(request)">{{ request.Title }}</a>
          </td>
          <td>{{ request.SourceType }}</td>
          <td>{{ request.PublishDate }}</td>
          <td>
            <span
              class="tag"
              :class="{
                'is-danger': request.Status === -1,
                'is-success': request.Status === 1,
              }"
              >{{ getStatusName(request.Status) }}
            </span>
          </td>
          <td>
            <div class="field is-grouped">
              <p class="control">
                <a
                  @click="declineRequest(request)"
                  class="level-item is-size-3 mr-3"
                  :class="{
                    'has-text-danger': request.Status === -1,
                    'has-text-grey-light': request.Status !== -1,
                  }"
                  title="Deny request"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'ban']" />
                  </span>
                </a>
              </p>
              <p
                class="control level-item is-size-3"
                :class="{ 'has-text-grey-light': request.Status !== 0 }"
              >
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'question']" />
                </span>
              </p>
              <p class="control">
                <a
                  @click="acceptRequest(request)"
                  class="level-item is-size-3 ml-3"
                  :class="{
                    'has-text-success': request.Status === 1,
                    'has-text-grey-light': request.Status !== 1,
                  }"
                  title="Accept request"
                >
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'check']" />
                  </span>
                </a>
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import channelProvider from '@/providers/channel'

export default {
  components: {
    ArticleSummary: () => import('@/components/Article/ArticleSummary'),
  },

  props: {},

  data() {
    return {
      isLoading: false,
      requests: [],

      drawerTitle: '',
      showSideDrawer: false,
      showArticleSummary: false,
      selectedArticle: '',
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
  },

  created() {
    this.getChannelArticlesPublishRequests()
  },

  methods: {
    getStatusName(status) {
      let name = ''

      switch (status) {
        case 0:
          name = 'Pending'
          break
        case 1:
          name = 'Accepted'
          break
        case -1:
          name = 'Declined'
          break
      }
      return name
    },

    getChannelArticlesPublishRequests() {
      if (!this.isLoading) {
        this.isLoading = true
        channelProvider.methods
          .getChannelArticlesPublishRequests(this.channel.Id)
          .then((response) => {
            this.requests = response.data
          })
          .catch((err) => {})
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    async acceptRequest(request) {
      await channelProvider.methods
        .acceptPublishRequests(request.Id)
        .then((response) => {
          if (response.status === 200) {
            let index = this.requests.findIndex((r) => r.Id === request.Id)

            if (index > -1) {
              Vue.set(this.requests, index, response.data)
            }
          }
        })
        .catch((err) => {})
        .finally(() => {})
    },

    async declineRequest(request) {
      await channelProvider.methods
        .declinePublishRequests(request.Id)
        .then((response) => {
          if (response.status === 200) {
            let index = this.requests.findIndex((r) => r.Id === request.Id)
            if (index > -1) {
              Vue.set(this.requests, index, response.data)
            }
          }
        })
        .catch((err) => {})
        .finally(() => {})
    },

    setShowArticleSummary(article) {
      this.showSideDrawer = true
      this.showArticleSummary = true
      this.selectedArticle = article.Key
    },

    hideDrawer() {
      this.selectedArticle = ''
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showArticleSummary = false
    },
  },
}
</script>

<style></style>
