var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showSideDrawer)?_c('ui-component-drawer',{attrs:{"drawerTitle":_vm.drawerTitle,"showDrawer":_vm.showSideDrawer,"onClickClose":_vm.hideDrawer,"position":"right","drawerSize":"extra-large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.showArticleSummary)?_c('ArticleSummary',{attrs:{"articleKey":_vm.selectedArticle}}):_vm._e()]},proxy:true}],null,false,3256138511)}):_vm._e(),_c('p',{staticClass:"title"},[_vm._v("Publish requests")]),_c('table',{staticClass:"table is-fullwidth is-striped is-hoverable"},[_vm._m(0),_c('tbody',_vm._l((_vm.requests),function(request,index){return _c('tr',{key:index},[_c('td',[_c('a',{on:{"click":function($event){return _vm.setShowArticleSummary(request)}}},[_vm._v(_vm._s(request.Title))])]),_c('td',[_vm._v(_vm._s(request.SourceType))]),_c('td',[_vm._v(_vm._s(request.PublishDate))]),_c('td',[_c('span',{staticClass:"tag",class:{
              'is-danger': request.Status === -1,
              'is-success': request.Status === 1,
            }},[_vm._v(_vm._s(_vm.getStatusName(request.Status))+" ")])]),_c('td',[_c('div',{staticClass:"field is-grouped"},[_c('p',{staticClass:"control"},[_c('a',{staticClass:"level-item is-size-3 mr-3",class:{
                  'has-text-danger': request.Status === -1,
                  'has-text-grey-light': request.Status !== -1,
                },attrs:{"title":"Deny request"},on:{"click":function($event){return _vm.declineRequest(request)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'ban']}})],1)])]),_c('p',{staticClass:"control level-item is-size-3",class:{ 'has-text-grey-light': request.Status !== 0 }},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'question']}})],1)]),_c('p',{staticClass:"control"},[_c('a',{staticClass:"level-item is-size-3 ml-3",class:{
                  'has-text-success': request.Status === 1,
                  'has-text-grey-light': request.Status !== 1,
                },attrs:{"title":"Accept request"},on:{"click":function($event){return _vm.acceptRequest(request)}}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}})],1)])])])])])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Source")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Published on")]),_c('th',[_vm._v(" ")])])])
}]

export { render, staticRenderFns }